import React from "react"
import Layout from "../components/Layout"

export default function Cv() {
  return (
    <Layout>
      <div className="deco top">
        <img
          src="/rechtsanwalt_schweikert.jpg"
          alt="Rechtsanwalt Friedrich Schweikert"
        />        
      </div>
      <div className="content">
        <h1>Rechtsanwalt & Strafverteidiger Friedrich Schweikert</h1>
        <table className="cv">
            <tbody>
              <tr>
                <td>1980</td>
                <td>&nbsp;</td>
                <td>geb. in Worms</td>
              </tr>
              <tr>
                <td>1996 </td>
                <td>&nbsp;</td>
                <td>Gr&uuml;ndung der Alphatec GbR</td>
              </tr>
              <tr>
                <td>1999 </td>
                <td>&nbsp;</td>
                <td>Abitur im Landheim Schondorf am Ammersee</td>
              </tr>
              <tr>
                <td>1999 </td>
                <td>&nbsp;</td>
                <td>Wehrdienst (W10)</td>
              </tr>
              <tr>
                <td>2000 </td>
                <td>&nbsp;</td>
                <td>
                  Gr&uuml;ndung und Gesch&auml;ftsf&uuml;hrung der{" "}
                  <a href="http://www.alphatec.de" target="new">
                    alphatec GmbH
                  </a>
                </td>
              </tr>
              <tr>
                <td>2001 </td>
                <td>&nbsp;</td>
                <td>
                  Dozent Java und PHP in Zusammenarbeit mit Agentur f&uuml;r
                  Arbeit
                </td>
              </tr>
              <tr>
                <td>2001 </td>
                <td>&nbsp;</td>
                <td>
                  Berater und Referent Recruiting IT (u.a. f&uuml;r AfA,
                  Systems, Computerwoche)
                </td>
              </tr>
              <tr>
                <td>2002 </td>
                <td>&nbsp;</td>
                <td>
                  Gr&uuml;ndung E-Garten.Net (erstes kommerzielles WLAN
                  weltweit)
                </td>
              </tr>
              <tr>
                <td>2004 </td>
                <td>&nbsp;</td>
                <td>Projektleitung E-Garten.Net</td>
              </tr>
              <tr>
                <td>2005 </td>
                <td>&nbsp;</td>
                <td>Wahlkampfteam MdB Jimmy Schulz</td>
              </tr>
              <tr>
                <td>2008 </td>
                <td>&nbsp;</td>
                <td>1. Staatsexamen Jura</td>
              </tr>
              <tr>
                <td>2008 </td>
                <td>&nbsp;</td>
                <td>
                  Referendariat u.a. bei Staatsanwaltschaft und Beschwerdekammer
                  Landgericht Traunstein, Amtsgericht Bad Aibling und
                  Landratsamt Rosenheim
                </td>
              </tr>
              <tr>
                <td>2010 </td>
                <td>&nbsp;</td>
                <td>2. Staatsexamen Jura</td>
              </tr>
              <tr>
                <td>2010 </td>
                <td>&nbsp;</td>
                <td>Gr&uuml;ndung der Kanzlei Schweikert</td>
              </tr>
            </tbody>
          </table>
          <p className="no-margin">
            Ehren&auml;mter u.a. bei Bergwacht Bayern und H&ouml;hlenrettung Bayern S&uuml;d
          </p>
          <img src="/u_schweikert.gif" alt="" />
      </div>
    </Layout>
  )
}
